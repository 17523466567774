<template>
  <div>
    <div v-if="label" class="form-label">{{ label }} <span v-if="required">*</span></div>
    <v-select
        :placeholder="placeholder"
        multiple
        :name="name"
        :items="items"
        v-model="model"
        variant="outlined"
        density="compact"
        :error-messages="errors"
        @blur="handleBlur"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'j-multiselect',
  inheritAttrs: true
}
</script>

<script setup>
import {defineModel, defineProps, toRef, watch} from 'vue';
import {useField} from 'vee-validate';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  items: {
    required: true
  },
  placeholder: {
    required: false
  },
  required: {
    type: Boolean,
    default: false
  }
});
const model = defineModel();

const {value, handleBlur, errors} = useField(
    toRef(props, 'name'),
    undefined,
    {
      label: toRef(props, 'label')
    }
);

watch(() => model.value, () => {
  value.value = model.value;
});
</script>

<style scoped>
.form-label, .col-form-label {
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cbcbe2;
}

.form-label span{
  color: red;
}
</style>