<template>
  <div>
    <div v-if="label" class="form-label">{{ label }} <span v-if="required">*</span></div>
    <v-text-field
        v-model="value"
        :append-inner-icon="appendInnerIcon"
        :error-messages="errors"
        density="compact"
        :placeholder="placeholder"
        :type="type"
        v-bind="$attrs"
        variant="outlined"
        @blur="handleBlur"
    >
    </v-text-field>
  </div>

</template>

<script>
export default {
  name: 'j-text-field',
  inheritAttrs: false
}
</script>

<script setup>
import {defineProps, reactive, toRef} from 'vue';
import {useField} from 'vee-validate';
import { vMaska, Mask } from "maska"

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: true,
  },
  appendInnerIcon: {
    type: String
  },
  required: {
    type: Boolean,
    default: false
  }
});

const {value, handleBlur, errors} = useField(toRef(props, 'name'), undefined, {
  label: toRef(props, 'label')
});
</script>

<style scoped>
.form-label, .col-form-label {
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cbcbe2;
}

.form-label span{
  color: red;
}
</style>