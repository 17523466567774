import axios from "axios";

class UserEventsAPI {
    static __SEARCH = "/api/events/search";

    search(page, pageSize) {
        return axios.get(UserEventsAPI.__SEARCH, {
            params: {
                page: page,
                pageSize: pageSize
            }
        })
    }
}

export default new UserEventsAPI();