<template>
  <VueDatePicker
      ref="menu"
      v-model="model"
      :autoApply="true"
      :enableTimePicker="false"
      locale="ru"
      menu-class-name="j-date-picker-menu"
      :min-date="minDate"
  >
    <template #trigger>
      <div v-if="label" class="form-label">{{ label }} <span v-if="required">*</span></div>
      <v-text-field
          append-inner-icon="date"
          :error-messages="errors"
          :readonly="true"
          :type="type"
          :model-value="formattedValue"
          density="compact"
          v-bind="$attrs"
          variant="outlined"
          @blur="handleBlur"
          @keydown.delete="clearValue"
      />
    </template>
  </VueDatePicker>
</template>

<script>
export default {
  name: "j-date-picker"
}
</script>

<script setup>
import {computed, defineProps, ref, toRef, watch} from 'vue';
import {useField} from 'vee-validate';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {beautyFormatDate} from "@/global/functions/date-helper";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  appendInnerIcon: {
    type: String
  },
  placeholder: {
    required: false
  },
  required: {
    type: Boolean,
    default: false
  },
});

let model = defineModel();

const emits = defineEmits(['update:modelValue']);

const {value, handleBlur, errors} = useField(toRef(props, 'name'), undefined, {
  label: toRef(props, 'label')
});

let menu = ref(null);
let clearValue = () => {
  menu.value.clearValue();
};

let onInput = (val) => {
  if (val === model.value) {
    return;
  }
  value.value = val;
  emits('update:modelValue', val);
};

// computed
let formattedValue = computed(() => beautyFormatDate(model.value));

watch(() => model.value, () => {
  value.value = model.value;
});

</script>

<style lang="scss">
.j-date-picker-menu {
  .dp__button {
    display: none;
  }
}

.form-label, .col-form-label {
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cbcbe2;
}

.form-label span{
  color: red;
}
</style>