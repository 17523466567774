<template>
  <Form id="init-reset-password"
        :validation-schema="schema"
        as="form"
        class="step-form"
        @submit="submit">
    <div class="form-label">E-mail</div>
    <j-text-field
        v-model="dataForm.email"
        append-inner-icon="mail"
        class="mb-3"
        placeholder="Введите свою почту"
        label="E-mail"
        name="email"
        type="email"
    />
    <v-card-actions class="d-flex align-center justify-space-between">
      <slot name="card-actions"></slot>
      <v-btn :loading="loading" rounded="lg" color="#696cff" form="init-reset-password" variant="tonal" type="submit">
        Далее
      </v-btn>
    </v-card-actions>
  </Form>
</template>

<script>
import {Form} from 'vee-validate';

export default {
  name: "init-form-pr",
  components: {Form},
}
</script>

<script setup>
import {defineEmits, ref} from 'vue';
import {useStore} from "vuex";

const store = useStore();
const emit = defineEmits(['next']);

// data
const dataForm = {
  email: null
}
const schema = {
  email: "required|email"
};
let loading = ref(false);

// methods
let submit = () => {
  emit('next', dataForm);
  loading.value = true;
};
let resetState = () => {
  loading.value = false;
};

defineExpose({
  resetState
})

</script>

<style lang="scss" scoped>
</style>