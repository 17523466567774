import axios from "axios";

class AdminUserAPI {

    static __SEARCH = "/api/admins/search";
    static __ASSIGN = "/api/admins/assign-admin";
    static __DISMISS = "/api/admins/dismiss";
    static __AVATAR_URL = "/api/admins/avatar/{avatarFileId}"

    getAvatarUrl(fileId) {
        return AdminUserAPI.__AVATAR_URL.replace('{avatarFileId}', fileId);
    }

    search(page, pageSize, email) {
        return axios.get(AdminUserAPI.__SEARCH, {
            params: {
                page: page,
                pageSize: pageSize,
                email: email
            }
        });
    }

    assign(email) {
        return axios.post(AdminUserAPI.__ASSIGN, null, {
            params: {
                email: email
            }
        });
    }

    dismiss(userId) {
        return axios.post(AdminUserAPI.__DISMISS + `/${userId}`);
    }
}

export default new AdminUserAPI();