<template>
  <form-wrapper>
    <h2 class="mb-2 ">Создайте аккаунт чтобы получить доступ к Voicebot 🚀</h2>
    <p class="mb-3">{{ header }}</p>
    <div class="slider">
      <v-scroll-x-reverse-transition>
        <data-step-registration v-show="showData" @next="enterData">
          <template v-slot:card-actions>
            <v-btn v-if="showBackBtn" rounded="lg" @click="backStep">Назад</v-btn>
          </template>
        </data-step-registration>
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition>
        <password-step-registration v-show="showPassword" ref="passwordStepFormRef" @next="enterData">
          <template v-slot:card-actions>
            <v-btn v-if="showBackBtn" rounded="lg" @click="backStep">Назад</v-btn>
          </template>
        </password-step-registration>
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition>
        <confirm-step-registration v-show="showConfirm" ref="confirmStepFormRef" @next="enterData"
                                   @resend="sendRegData"/>
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition>
        <success-step-registration v-show="showSuccess"/>
      </v-scroll-x-reverse-transition>
    </div>
    <div v-if="showLoginBtn" class="mt-2 text-center">
      <p class="text-body-2">
        Уже есть аккаунт?
        <router-link class="link text-caption text-decoration-none text-blue" to="login">
          Войти
        </router-link>
      </p>
    </div>
  </form-wrapper>
</template>

<script>
import FormWrapper from '../components/form-wrapper';
import DataStepRegistration from "./forms/data-step-registration";
import PasswordStepRegistration from "./forms/password-step-registration";
import ConfirmStepRegistration from "./forms/confirm-step-registration";
import SuccessStepRegistration from "./forms/success-step-registration";
import RegistrationService from "./service/registration-service";

export default {
  name: "register-form",
  components: {
    FormWrapper,
    DataStepRegistration,
    PasswordStepRegistration,
    ConfirmStepRegistration,
    SuccessStepRegistration
  }
}
</script>

<script setup>
import {computed, ref, defineExpose} from "vue";
import {useStore} from "vuex";
import {STEPS} from "./forms/constants";
import {useRouter} from "vue-router";

const store = useStore();
const router = useRouter();

// refs
const passwordStepFormRef = ref(null);
const confirmStepFormRef = ref(null);
defineExpose({passwordStepFormRef, confirmStepFormRef});

// computed
let step = computed(() => store.getters.getRegistrationStep);
let header = computed(() => {
  if (step.value === STEPS.DATA) {
    return "Заполните данные";
  }
  if (step.value === STEPS.PASSWORD) {
    return "Придумайте пароль";
  }
  if (step.value === STEPS.CONFIRM) {
    return "Подтверждение";
  }
  if (step.value === STEPS.SUCCESS) {
    return "";
  }
});
let showData = computed(() => step.value === STEPS.DATA);
let showPassword = computed(() => step.value === STEPS.PASSWORD);
let showConfirm = computed(() => step.value === STEPS.CONFIRM);
let showSuccess = computed(() => step.value === STEPS.SUCCESS);
let showBackBtn = computed(() => [STEPS.PASSWORD].includes(step.value));
let showLoginBtn = computed(() => [STEPS.DATA, STEPS.PASSWORD, STEPS.CONFIRM].includes(step.value));

// methods
let nextStep = () => {
  store.dispatch('nextRegistrationStep');
};
let backStep = () => {
  store.dispatch('backRegistrationStep');
};

let sendRegData = () => {
  let data = store.getters.getRegistrationData;
  return RegistrationService.sendRegistrationData(data);
};

let enterData = (dataForm) => {
  console.log(dataForm);
  console.log(showData.value);
  console.log(showPassword.value);
  if (showData.value) {
    store.dispatch('setRegistrationData', dataForm);
    nextStep();
    return;
  }
  if (showPassword.value) {
    let data = store.getters.getRegistrationData;
    if (!data || !data.email || !data.name || !data.phone) {
      store.dispatch('setRegistrationData', null);
      backStep();
      return;
    }
    data.password = dataForm.password;
    data.repeatedPassword = dataForm.repeatedPassword;
    store.dispatch('setRegistrationData', data);

    sendRegData()
        .then(() => {
          nextStep();
        })
        .catch(() => {
          passwordStepFormRef.value.resetState();
        });
    return;
  }
  if (showConfirm.value) {
    RegistrationService.sendConfirm(dataForm)
        .then(() => {
          nextStep();
          store.dispatch('setRegistrationData', null);
        })
        .catch(() => {
          confirmStepFormRef.value.resetState();
        });
    return;
  }
};
</script>

<style lang="scss" scoped>

h2 {
  color: rgba(var(--v-theme-heading-color), 0.9);
  font-weight: 500;
}

.link {
  cursor: pointer;
}
</style>