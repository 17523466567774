<template>
  <v-container class="main-card card-panel">
    <div class="header-section">
      <v-btn
          v-if="arrowBack"
          rounded
          variant="text"
          size="30"
          class="mr-2"
          @click="goBack"
      >
        <v-icon size="20">arrow_back</v-icon>
      </v-btn>
      <h3 :class="arrowBack ? null : 'ml-4'">{{ header }}</h3>
    </div>
    <div class="content-section">
      <slot name="default"/>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      require: true
    },
    arrowBack: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
}

</script>

<style scoped lang="scss">
.main-card {
  position: relative;
  background-color: rgb(43, 44, 64);

  .header-section {
    color: rgb(213, 213, 226);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
  }

  .content-section {
    padding-top: 30px;
  }
}
</style>