import axios from "axios";
import store from "@/store";



class ReferenceAPI {

    static __AUTH_METHODS = "/api/reference/auth-methods";
    static __GRANT_TYPES = "/api/reference/grant-types";
    static __SCOPES = "/api/reference/scopes";

    getAuthMethods() {
        return axios.get(ReferenceAPI.__AUTH_METHODS);
    }

    getGrantTypes() {
        return axios.get(ReferenceAPI.__GRANT_TYPES);
    }

    getScopes() {
        return axios.get(ReferenceAPI.__SCOPES);
    }
}

export default new ReferenceAPI();