import axios from "axios";

class UserTokensAPI {
    static __SEARCH = "/api/tokens/search";
    static __RECALL = "/api/tokens/recall";

    search() {
        return axios.get(UserTokensAPI.__SEARCH);
    }

    recall(authorizationId) {
        return axios.post(UserTokensAPI.__RECALL, null, {
            params: {
                authorizationId: authorizationId
            }
        });
    }
}

export default new UserTokensAPI();