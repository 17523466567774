import axios from 'axios';

export class HomeAPI {

    static __USER_DATA_URL = "/api/account/current";

    getCurrentUser() {
        return axios.get(HomeAPI.__USER_DATA_URL);
    }

    saveCurrentUser(dto, file) {
        const form = new FormData();
        form.append('file', file);
        form.append('dto', new Blob([JSON.stringify(dto)], {
            type: "application/json"
        }));
        return axios.post(HomeAPI.__USER_DATA_URL, form);
    }

    deleteAccount() {
        return axios.delete(HomeAPI.__USER_DATA_URL);
    }
}


export default new HomeAPI();