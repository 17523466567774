<template>
  <form-wrapper>
    <h2 class="mb-2 ">Добро пожаловать! 👋</h2>
    <p class="mb-4">Пожалуйста войдите в аккаунт чтобы продолжить</p>
    <Form :validation-schema="schema" as="form" @submit="login">
      <j-text-field
          v-model="username"
          class="mb-3 custom-placeholder-color"
          placeholder="Введите свою почту"
          label="E-mail"
          name="email"
          type="email"
      >
      </j-text-field>
      <div class="form-label d-flex align-center justify-space-between">
        Пароль

        <router-link  class="text-caption text-decoration-none text-blue" to="reset-password">
          Забыли пароль?
        </router-link>
      </div>
      <j-text-field
          v-model="password"
          required
          :append-inner-icon="cPasswordIcon"
          :type="passwordType"
          class="mb-3 custom-placeholder-color"
          placeholder="············"
          name="password"
          @click:appendInner="togglePasswordVisible"
      >
      </j-text-field>
      <v-btn rounded="lg" color="#696cff" variant="tonal" class=" d-grid w-100" type="submit">
        Войти
      </v-btn>
    </Form>
    <v-card-text class="text-center">
      Нет аккаунта? <router-link  class="text-caption text-decoration-none text-blue" to="registration">
        Создать аккаунт
      </router-link>
    </v-card-text>
  </form-wrapper>
</template>

<script>

import {computed, onMounted, ref} from "vue";
import LoginAPI from './service/login-service';
import {Form} from 'vee-validate';
import FormWrapper from "@/views/sign-view/components/form-wrapper";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import ClientOauth2Form from "../components/client-oauth2-form/client-oauth2-form.vue";
import {showENotify} from "@/global/functions/notification-funcs";

export default {
  name: "login-form",
  components: {ClientOauth2Form, FormWrapper, Form},
  setup() {
    const store = useStore();
    const router = useRouter()
    let username = ref(null);
    let password = ref(null);
    let passwordType = ref("password");
    const schema = {
      email: 'required|email',
      password: "required"
    };

    let login = () => {
      LoginAPI.login(username.value, password.value)
          .catch(result => {
            if (result.status === 401) {
              showENotify("Не верный логин или пароль");
              return;
            }
            return result;
          });
    }

    let togglePasswordVisible = () => {
      if (passwordType.value === "password") {
        passwordType.value = "text";
      } else {
        passwordType.value = "password";
      }
    };

    let goToRegistration = () => {
      router.push("registration");
    };

    let goToResetPassword = () => {
      router.push("reset-password");
    };

    onMounted(() => {
      // reset state for registration process
      store.dispatch('setRegistrationData', null);
      store.dispatch('setRegistrationStep', 0);

      // reset state for forgot password process
      store.dispatch('setResetData', null);
      store.dispatch('setResetStep', 0);
    });

    return {
      // data
      username,
      password,
      passwordType,
      schema,
      // methods
      login,
      togglePasswordVisible,
      goToRegistration,
      goToResetPassword,
      // computed
      cPasswordIcon: computed(() => passwordType.value === 'password' ? "visibility" : "visibility_off")
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: rgba(var(--v-theme-heading-color), 0.9)
}

.link {
  cursor: pointer;
}

.form-label, .col-form-label {
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: inherit;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cbcbe2;
}

.custom-placeholder-color input::placeholder {
  color: red !important;
  opacity: 1;
}
</style>