import NotificationMixin from './notification-mixin';
import DateHelperMixin from './date-helper-mixin'


export default {
    install(Vue, opt) {
        Vue.mixin(NotificationMixin);
        Vue.mixin(DateHelperMixin);
    }
}
