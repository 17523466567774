<template>
  <div class="container">
    <p class="ivoice">iVoice <span class="id">ID</span></p>
  </div>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Macondo&family=Madimi+One&display=swap');

.container {
  position: relative;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
  top: 0px;
  text-align: center;
}

p {
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  z-index: 1;
  color: #cbcbe2;
}


.container span.id {
  color: #2b2c40;
  padding: 0 10px;
  font-size: 24px;
  height: 32px;
  background-color: #cbcbe2;
  border-radius: 10px;
  width: 24px;
  margin-left: 10px;
  font-family: "Macondo", cursive;
  font-weight: 600;
  font-style: normal;

  /*  animation: textcolor 0.30s linear forwards; */
}

*,
*:after,
*:before {
  transform: translateZ(0);
}

</style>