<template>
  <Form id="data-step-registration"
        :validation-schema="schema"
        as="form"
        class="step-form"
        @submit="submit">
    <div class="form-label">E-mail</div>
    <j-text-field
        v-model="dataForm.email"
        placeholder="Введите вашу почту"
        label="Почта"
        required
        class="mb-1"
        name="email"
        type="email"
    />

    <div class="form-label">Имя</div>
    <j-text-field
        v-model="dataForm.name"
        placeholder="Введите ваше имя"
        label="Имя"
        class="mb-1"
        name="name"
        type="text"/>

    <div class="form-label">Номер телефона</div>
    <j-text-field
        placeholder="+7 (999) 999-99 99"
        class="mb-1"
        required
        name="phone"
        type="text"
        label="Номер телефона"
        v-model="dataForm.phone"
    ></j-text-field>
    <v-card-actions class="d-flex align-center justify-space-between">
      <slot name="card-actions"></slot>
      <v-btn rounded="lg" color="#696cff" variant="tonal" type="submit">
        Далее
      </v-btn>
    </v-card-actions>
  </Form>
</template>

<script>
import {Form} from 'vee-validate';

export default {
  name: "data-step-registration",
  components: {Form}
}
</script>

<script setup>
import {computed, defineEmits, watch, reactive} from 'vue';
import {useStore} from "vuex";
import {STEPS} from "@/views/sign-view/registration/forms/constants";


const store = useStore();
const emit = defineEmits(['next']);
// data
const dataForm = {
  email: null,
  name: null,
  phone: null,
}
const schema = {
  email: "required|email",
  name: "required",
  phone: "required"
};

// computed
let isShowed = computed(() => store.getters.getRegistrationStep === STEPS.DATA);
let storedRegistrationData = computed(() => store.getters.getRegistrationData);

// watch
watch(() => isShowed.value, (value) => {
  if (value) {
    if (!!storedRegistrationData.value) {
      dataForm.email = storedRegistrationData.value.email;
      dataForm.phone = storedRegistrationData.value.phone;
      dataForm.name = storedRegistrationData.value.name;
    }
  }
});

// methods
let submit = () => {
  emit('next', dataForm);
};
</script>


<style lang="scss" scoped>
</style>