<template>
  <main-card header="OAuth2 клиенты" class="admin-oauth-clients">
    <Form id="search-form"
          :validation-schema="schema"
          as="form"
          @submit="()=>search()">
      <v-row class="ma-1" justify="space-between">
        <v-col cols="6" sm="2">
          <v-select density="compact" :items="sizes" v-model="pageSize" variant="outlined" name="pageSize"/>
        </v-col>
        <v-col cols="12" sm="5">
          <j-text-field  name="search" v-model="searchValue" append-inner-icon="search"
                        type="text"/>
        </v-col>
      </v-row>
      <v-row class="ma-1" justify="space-between">
        <v-col cols="12" sm="2">
          <v-btn
              variant="elevated"
              color="primary"
              form="search-form"
              type="submit"
          >Поиск
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn variant="tonal"
                 prepend-icon="add"
                 @click="createNew"
                 color="primary">
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </Form>
    <v-divider class="mt-4 mb-4"/>
    <v-data-table
        :headers="headers"
        :items="clientList"
        item-value="name"
        hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            class="me-2"
            size="small"
            @click="openClientCard(item)"
        >
          edit
        </v-icon>
        <v-icon
            size="small"
            @click="deleteClient(item)"
        >
          delete
        </v-icon>
      </template>
      <template v-slot:bottom>
        <v-row justify="space-between">
          <v-col cols="12" sm="12" md="6">
            <div class="info-table">
              Отображается от {{ beginElements }} до {{endElements}} из {{ allItemsCont }} записей
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-pagination
                :model-value="page"
                :total-visible="7"
                :length="countPage"
                active-color="primary"
                density="compact"
                @update:model-value="search"
            ></v-pagination>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <j-confirmation-dialog
        v-model="confirmDialogOpen"
        :header="confirmDialog.header"
        @confirm="confirmDialog.onConfirm"
        @cancel="confirmDialog.onCancel"
    />
  </main-card>
</template>

<script setup>

import MainCard from "@/views/main/components/main-card.vue";
import Service from './service/oauth-client-service';
import {onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {showSNotify} from "@/global/functions/notification-funcs";
import {defineRule, Form} from "vee-validate";

const router = useRouter();

const page = ref(1);
const pageSize = ref(10);
const countPage = ref(1);


const clientList = ref([]);
const allItemsCont = ref(0);
const beginElements = ref(1);
const endElements = ref(pageSize.value);

const searchValue = ref(null);
const searchFilterBeforeEdit = ref(null);
const sizes = [
    10,
    25,
    100
]

const headers = [
  {
    title: "ID",
    sortable: false,
    key: 'clientId',
  },
  {
    title: "НАИМЕНОВАНИЯ",
    sortable: false,
    key: 'clientName',
  },
  {
    title: "СРОК ГОДНОСТИ",
    sortable: false,
    key: 'clientSecretExpiresAt',
  },
  { title: 'ДЕЙСТВИЯ', key: 'actions', sortable: false },
]

const confirmDialog = ref({
  header: "",
  onConfirm: () => {
  },
  onCancel: () => {
  }
});
const confirmDialogOpen = ref(false);
const schema = {
  searchValue: "search_field_max:200",
};
defineRule('search_field_max', (value, [limit]) => {
  if (!!value && value.length > limit) {
    return `Максимум ${limit} символов`;
  }
  return true;
});

function search(pageVal) {
  if (pageVal !== null && pageVal !== undefined) {
    page.value = pageVal;
  } else {
    pageVal = page.value;
  }

  let filtersValue = `${searchValue.value}|${pageVal}`;
  if (searchFilterBeforeEdit.value === filtersValue) {
    return Promise.resolve();
  }
  searchFilterBeforeEdit.value = filtersValue;

  return callSearchApi(pageVal);
}

function refresh(pageVal) {
  if (pageVal !== null && pageVal !== undefined) {
    page.value = pageVal;
  } else {
    pageVal = page.value;
  }
  return callSearchApi(pageVal);
}

function callSearchApi(pageVal) {
  return Service.search(pageVal - 1, pageSize.value, searchValue.value)
      .then(result => {
        console.log(result)
        clientList.value = result.data.content;
        allItemsCont.value = result.data.totalElements;
        countPage.value = result.data.totalPages;
        beginElements.value = 1 + (pageVal - 1) *  pageSize.value;
        endElements.value  = pageVal *  pageSize.value;
        if (endElements.value > allItemsCont.value) {
          endElements.value = allItemsCont.value;
        }
      });
}

function openClientCard(dto) {
  router.push({
    name: 'admin-oauth-client', params: {
      clientId: dto.clientId
    }
  });
}

function deleteClient(dto) {
  let clientId = dto.clientId;
  confirmDialog.value = {
    header: `Вы уверенны что хотите удалить клиент: ${dto.clientName} (${clientId})?`,
    onConfirm: () => {
      Service.delete(clientId).then(() => {
        showSNotify(`Клиент ${dto.clientName} (${clientId}) успешно удален`);
        refresh();
      });
    },
    onCancel: () => {
    }
  };
  confirmDialogOpen.value = true;
}

function createNew() {
  router.push({
    name: 'admin-oauth-client', params: {
      clientId: 'new'
    }
  });
}

watch(pageSize, () => callSearchApi(page.value))

onMounted(() => {
  search();
});
</script>

<style scoped lang="scss">
.admin-oauth-clients {
  .form-actions {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .v-btn {
      height: 56px;
      width: 100%;
    }
  }
}
.info-table {
  color: #7e7f96;
}
</style>