import axios from "axios";

export class RegistrationAPI {
    static REGISTRATION_URL = "/api/registration/init";
    static CONFIRM_URL = "/api/registration/confirm";

    sendRegistrationData(data) {
        return axios.post(RegistrationAPI.REGISTRATION_URL, data);
    }

    sendConfirm(code) {
        return axios.post(RegistrationAPI.CONFIRM_URL, null, {
            params: {
                otp: code
            }
        });
    }
}

export default new RegistrationAPI();