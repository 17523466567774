import axios from "axios";

class OauthClientAPI {

    static __GET = "/api/oauth2/clients/{clientId}";
    static __SEARCH = "/api/oauth2/clients/search";
    static __SAVE = "/api/oauth2/clients";
    static __DELETE = "/api/oauth2/clients/{clientId}";
    static __GEN_SECRET = "/api/oauth2/clients/gen-secret/{clientId}";

    static __create_url_get(clientId) {
        return OauthClientAPI.__GET.replace("{clientId}", clientId);
    }

    static __create_url_delete(clientId) {
        return OauthClientAPI.__DELETE.replace("{clientId}", clientId);
    }

    static __create_url_gen_secret(clientId) {
        return OauthClientAPI.__GEN_SECRET.replace("{clientId}", clientId);
    }

    get(clientId) {
        let url = OauthClientAPI.__create_url_get(clientId);
        return axios.get(url, {
            params: {
                clientId: clientId
            }
        });
    }

    search(page, pageSize, search) {
        return axios.get(OauthClientAPI.__SEARCH, {
            params: {
                page: page,
                pageSize: pageSize,
                search: search
            }
        });
    }

    save(dto) {
        return axios.post(OauthClientAPI.__SAVE, dto);
    }

    delete(clientId) {
        let url = OauthClientAPI.__create_url_delete(clientId);
        return axios.delete(url, {
            params: {
                clientId: clientId
            }
        });
    }

    genSecret(clientId) {
        let url = OauthClientAPI.__create_url_gen_secret(clientId);
        return axios.post(url, null);
    }
}

export default new OauthClientAPI();