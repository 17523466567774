// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/_styles.scss'

import {createVuetify} from 'vuetify/lib/framework'
import {aliases, md} from 'vuetify/lib/iconsets/md';
import {aliases as aliasesMdi, mdi} from 'vuetify/lib/iconsets/mdi';
import {en, ru} from 'vuetify/lib/locale'


export default {
    install(Vue, opts) {
        Vue.use(createVuetify({
            theme: {
                defaultTheme: 'dark',
                themes: {
                    dark: {
                        colors: {
                            'heading-color': '#cbcbe2',
                            background: "#232333",
                            primary: '#696cff',
                            surface: '#2b2c40',
                            secondary: '#8592a3',
                            success: "#71dd37",
                            danger: '#ff3e1d',
                            warning: '#ffab00',
                            'on-surface': "#a3a4cc",
                            'surface-bright': '#FFFFFF',
                            'surface-light': '#4f505c',
                            'surface-variant': '#6b6c9d',
                            'on-surface-variant': '#EEEEEE',
                            'primary-darken-1': '#2a2b66',
                            'secondary-darken-1': '#353a41',
                        },
                    },
                },
            },
            locale: {
                locale: 'ru',
                fallback: 'en',
                messages: {ru, en},
            },
            icons: {
                defaultSet: 'md',
                aliases,
                aliasesMdi,
                sets: {
                    md,
                    mdi
                }
            },
        }));
    }
}
