<template>
  <main-card header="Профиль" class="profile-view">
    <Form id="profile-form"
          :validation-schema="schema"
          as="form"
          @submit="save">
      <v-row>
        <v-col cols="2" class="avatar-col">
          <v-avatar
              color="brown"
              size="large"
          >
            <span class="text-h5">{{ initials }}</span>
          </v-avatar>
        </v-col>
        <v-col cols="10">
          <div class="text-block">
            <div class="label">E-mail</div>
            <h4 class="value ma-2">{{ userData.email }}</h4>
          </div>
          <div class="text-block">
            <div class="label">ID</div>
            <h4 class="value ma-2">{{ userData.id }}</h4>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-10"/>
      <v-row class="mt-2">
        <v-col cols="12" md="6" lg="6">
          <j-text-field
              label="Имя"
              placeholder="Ваше имя"
              required
              name="name"
              class="mb-3 custom-placeholder-color"
              v-model="userData.name"
              type="text"/>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <j-text-field
              label="Номер телефона"
              placeholder="Ваш номер телефона"
              required
              name="phone"
              type="text"
              class=" mb-3 custom-placeholder-color"
              v-model="userData.phone"
          />
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-btn variant="plain" color="secondary" class="mr-2" @click="cancel">Отмена</v-btn>
        <v-btn variant="outlined"
               color="primary"
               class="mr-2 ml-2"
               form="profile-form"
               type="submit"
        >
          Сохранить
        </v-btn>
      </v-row>
    </Form>
  </main-card>
</template>

<script>
import {Form} from 'vee-validate';

export default {
  name: "account-form",
  components: {Form}
}
</script>

<script setup>
import Service from '../service/account-service';
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import MainCard from "@/views/main/components/main-card.vue";
import {showSNotify, showWNotify} from "@/global/functions/notification-funcs";
import LoginService from "@/views/sign-view/login/service/login-service";

const store = useStore();
const router = useRouter();

// data
let userData = ref({});
let userDataBeforeEdit = ref({});

const schema = {
  name: "required|max:100",
  phone: "required|max:100",
};

//methods
let cancel = () => {
  userData.value = userDataBeforeEdit.value;
  newAvatarData.value = null;
};

function load() {
  Service.getCurrentUser().then(result => {
    setUpUserData(result);
  });
}

function setUpUserData(result) {
  userData.value = result.data;
  userDataBeforeEdit.value = result.data;
}

function save() {
  if (userDataBeforeEdit.value === userData.value) {
    showWNotify("Нет изменений для сохранения");
    return;
  }
  Service.saveCurrentUser(
      userData.value,
      !!newAvatarData.value ? newAvatarData.value.file : null
  ).then((result) => {
    showSNotify("Данные успешно сохранены");
    setUpUserData(result);
    LoginService.getCurrentUser();
  });
}


// computed
let fullUserName = computed(() => {
  let data = userData.value;
  return `${data.name}`;
});
let initials = computed(() => {
  let data = userData.value;
  if (data.name) {
    return data.name.substring(0, 2);
  }
  return null;
});
let email = computed(() => {
  return userData.value.email;
});
let id = computed(() => userData.value.id);
let avatarSrc = computed(() => {
  if (!!newAvatarData.value && !!newAvatarData.value.value) {
    return newAvatarData.value.value;
  }
  return null;
});


onMounted(() => {
  load();
})
</script>

<style scoped lang="scss">
.profile-view {
  background-color: #2b2c40;

  .text-block {
    display: flex;
    align-items: center;

    .label {
      min-width: 50px;
    }
  }

  .avatar-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-col {
    padding: 5px !important;
  }

  .v-field__input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

</style>