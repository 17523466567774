<template>
  <v-container class="pt-1">
    <v-card class="mx-auto pa-10 pb-8" elevation="8" max-width="480" rounded="lg" max-height="800">
      <Logo/>
      <slot name="default"/>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "form-wrapper"
}
</script>

<style lang="scss" scoped>

</style>